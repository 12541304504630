$(function() {
    console.log('Document ready');

    function isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    const $headerSlider = $('#slider-header'),
        $newsSlider = $('#slider-news'),
        $mainMenu = $('#main-menu');
    if ($headerSlider.length) {
        $headerSlider.slick({
            arrows:        false,
            fade:          true,
            autoplay:      true,
            autoplaySpeed: 4000,
            speed:         1000,
            pauseOnHover:  false,
            pauseOnFocus:  false
        });
    }
    if ($newsSlider.length) {
        $newsSlider.slick({
            slidesToShow: 4,
            infinite:     false,
            dots:         true,
            swipeToSlide: true,
            prevArrow:    '<span class="btn btn_prev"></span>',
            nextArrow:    '<span class="btn btn_next"></span>',
            responsive:   [{
                breakpoint: 1199,
                settings:   {
                    slidesToShow: 3,
                    arrows:       false
                }
            }, {
                breakpoint: 992,
                settings:   {
                    slidesToShow: 2,
                    arrows:       false
                }
            }, {
                breakpoint: 576,
                settings:   {
                    slidesToShow: 1,
                    arrows:       false,
                    dots:         false
                }
            }]
        });
    }
    // Стенды
    if (isMobile()) {
        const $bigStandSlider = $('.js-bigstand-slider'),
            $mediumStandSlider = $('.js-mediumstand-slider'),
            $smallStandSlider = $('.js-smallstand-slider');
        if ($bigStandSlider.length) {
            $bigStandSlider.slick({
                slidesToShow:   1,
                slidesToScroll: 1,
                arrows:         false,
                dots:           true
            });
        }
        if ($mediumStandSlider.length) {
            $mediumStandSlider.slick({
                slidesToShow:   1,
                slidesToScroll: 1,
                arrows:         false,
                dots:           true
            });
        }
        if ($smallStandSlider.length) {
            $smallStandSlider.slick({
                slidesToShow:   2,
                slidesToScroll: 2,
                arrows:         false,
                dots:           true
            });
        }
    }
    $(window)
        .resize(function() {

        })
        .on('orientationchange', function() {

        })
        .on('scroll', function() {
            const st = $(this).scrollTop();
            if (st >= 695) {
                $mainMenu.addClass('fixed');
                if (st >= 800) {
                    $mainMenu.addClass('show');
                } else {
                    $mainMenu.removeClass('show');
                }
            } else {
                $mainMenu.removeClass('fixed');
            }
        });
});